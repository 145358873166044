import React from 'react'
import styled from 'styled-components'
import { StaticImage } from "gatsby-plugin-image"

const Globalwrapper = styled.section `
position: relative;
top: -187px;
padding-top: 60px;
padding-bottom: 60px;
`
const Wrapper = styled.article`

max-width: 420px ;
margin: 0 auto;
padding: 0 1.5rem;

h1 {
    position: relative;
    display: block;
    width: max-content;
    font-weight: normal;
    padding-top: 60px;
    
    
}

h1::before {
    content: "";
    position: absolute;
    width: 100%;
height: 5px;
left: 0px;
top: 112px;
background: #293B5C;

}

.btn {
    width: 100%;
}
.btn::first-letter{
    text-transform: capitalize;
}
::placeholder {
    color:#293B5C ;
}
.about-img-bottom{
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: -1;

}
.about-img-top{
    position: absolute;
    top: -38px;
    right: 0;
    z-index: -1;

}
`
const Label = styled.label`
display: block;
border-radius: 10px;
  margin-bottom: 0.75rem;
  text-transform: capitalize;
`
const Form = styled.form `
    width: 100%;
    background: transparent;
   
    `
const Input = styled.input  `

  width: 100%;
  height: 40px;
  padding: 0.375rem 0.75rem;
  border-radius: 10px;
  background: #fff;
  border: 2px solid #293B5C;
  
  `
  const Textarea = styled.textarea`
  width: 100%;
  height: 206px;
  padding: 0.375rem 0.75rem;
  border-radius: 10px;
  background: #fff;
  border: 2px solid #293B5C;
  `
const Formrow = styled.div `
    margin-bottom: 1rem;
    `
  


const Contact = () => {
    return (
        <Globalwrapper >   
   <Wrapper id="contact">
        

    
            <h1>Skontaktuj się z nami</h1>
        <Form className="form contact-form">
          <Formrow className="form-row">
            <Label htmlFor="name">imię</Label>
            <Input type="text" name="name" id="name" />
          </Formrow>
          <Formrow className="form-row">
            <Label htmlFor="surname">nazwisko</Label>
            <Input type="text" name="surname" id="surname" />
          </Formrow>
          <Formrow className="form-row">
            <Label htmlFor="email">e-mail</Label>
            <Input type="text" name="email" id="email" />
          </Formrow>
          <Formrow className="form-row">
            <Label htmlFor="message">wiadomość</Label>
            <Textarea name="message" id="message"></Textarea>
          </Formrow>
          <button type="submit" className="btn block">
            wyślij wiadomość
          </button>
        </Form>
      </Wrapper>
      </Globalwrapper>
      
    )
}

export default Contact
