import React from 'react'
import {useStaticQuery, graphql} from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import styled from 'styled-components'
import { device } from '../Device/Device';
 

const Team = ({ queryName }) => {
    const data = useStaticQuery(graphql`
    {
        allFile(filter: {relativeDirectory: {eq: "team"}}) {
          nodes {
            name
            childImageSharp {
              gatsbyImageData(layout: CONSTRAINED, placeholder: TRACED_SVG, width: 317)
            }
            childTeamJson {
              data {
                desc
                name
                title
                
              }
            }
          }
        }
      }
    `)
    
  
 
  
  const nodes = data.allFile.nodes
  const images = nodes.filter(item => item.name !== queryName);
  const jsonTxt = nodes.filter(item => item.name == queryName)
  console.log(nodes)
  console.log(images)
  console.log(jsonTxt[0])


  return (
<GlobalWrapper id="team" className="globalWrapper">
<Title>Zespół</Title>
    <Wrapper>
        
        {jsonTxt[0] ? jsonTxt[0].childTeamJson.data.map((item, index) => {
              const image = images.find(el => el.name == item.name);
              const { name, desc, title } = item
              const pathToImage = getImage(image)
              return (
                <article key={index} className="item">
                <GatsbyImage
                  image={pathToImage}
                  alt={name}
                  className="gallery-img"
                />
                <Wrapperdesc>
                <h3>{title}</h3>
                <p>{desc}</p>
                </Wrapperdesc>
              </article>
              )
        }) : null}
     
    </Wrapper>
    </GlobalWrapper>
  )
}
const GlobalWrapper = styled.section `
 padding-top: 60px;
 padding-bottom: 60px;
  position: relative;
  top: -187px;
  background:  rgba(180, 176, 176, 0.3);
  z-index: 1;
  &:after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
   left: 0;
    width: 0;
    height: 0;
    border-top: 300px solid #fff;
    border-left: 100vw solid transparent;
    z-index: -1;
  }


`
const Title = styled.h1`
position: relative;
display: block;
    width: fit-content;
    font-weight: normal;
    

      &:before {
          content: "";
          position: absolute;
          width: 166px;
          height: 5.03px;
          left: 0px;
          top: 50px;
          background: #293B5C;

      }
      @media ${device.tablet} {
        margin-bottom:72px ;
      }
`


const Wrapper = styled.div`
  display: flex;
  justify-content: center;
 flex-direction: column;
  align-items: center;
@media ${device.tablet} {
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: row;
}
 
 
  .item {
      width: 100%;
      display: flex;
      flex-direction: column;
      border-radius: 20px 20px 0px 0px;
      margin-bottom: 30px;
      max-width: 316px;
     
     
       @media (min-width: 768px){
          width: 30%;
          
          
          
          
  }
}
  .item:first-child {
    padding-top: 60px;
@media ${device.tablet}{
  padding-top: 0;
}
 
  }
  .gallery-img {
      
      height: 300px ;
      border-radius: 20px 20px 0px 0px;
      overflow: hidden;

      @media ${device.tablet} {
        height: 250px;
      }
    
  }
 
  
`
const Wrapperdesc = styled.div `
display: flex;
flex-direction: column;
gap: 24px;
border-radius:0px 0px 20px 20px;
background-color: #FFF;
padding: 16px 16px 37px 16px ;
width: 100%;
overflow: hidden;

h3 {
    text-align: center;
}

p{
      color: black;
  }
  @media ${device.tablet} {
        height: 300px;
      }
      @media ${device.laptop} {
        height: 250px;
      }
`


export default Team
