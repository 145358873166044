import React, { useEffect, useState } from "react";
import { AiFillUpCircle } from '@react-icons/all-files/ai/AiFillUpCircle';
import styled from 'styled-components'
import { device } from "../Device/Device";

export default function ScrollToTop() {
  const [isVisible, setIsVisible] = useState(false);

  // Show button when page is scorlled upto given distance
  const toggleVisibility = () => {
    if (window.pageYOffset > 1200) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  // Set the top cordinate to 0
  // make scrolling smooth
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", toggleVisibility);
  }, []);

  return (
    <Scroll className="scroll-to-top">
        
      {isVisible && 
        <div onClick={scrollToTop}>
          <span><AiFillUpCircle/></span>
        </div>}
    </Scroll>
  );
}


const Scroll = styled.div `
position: fixed;
bottom: 5%;
right: 5%;
z-index: 1000;

span svg {
    height: 50px;
    width: 50px;
    color: rgba(223, 179, 76, 1);
}
@media ${device.tablet} {
    span svg {
        height: 75px;
        width: 75px;
    }
}
`