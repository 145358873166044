import React from 'react'
import { Link } from 'gatsby'
import ScrollAnimation from 'react-animate-on-scroll';
import { AnchorLink } from "gatsby-plugin-anchor-links";


function Button({link, name}) {
    return (
        <ScrollAnimation animateIn='flipInX'>
            <AnchorLink className="btn"  to={link}>{name}</AnchorLink>
            </ScrollAnimation>
    )
}


export default Button
