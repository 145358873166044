import React, {useRef} from 'react'
import { device } from '../Device/Device';
import {useStaticQuery, graphql} from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import styled from 'styled-components'
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation } from 'swiper';
import 'swiper/swiper-bundle.css'
import { AiFillRightCircle } from '@react-icons/all-files/ai/AiFillRightCircle';
import { AiFillLeftCircle } from '@react-icons/all-files/ai/AiFillLeftCircle';
SwiperCore.use([Navigation]);


const Globalwrapper = styled.section `
position: relative;
top: -187px;
background-color: #fff;
padding-top: 60px;
padding-bottom: 60px;
margin: 0 auto;


h1 {
    position: relative;
    width: max-content;
    font-weight: normal;
    padding-bottom: 40px;

}
h1::before {
    content: "";
    position: absolute;
    left: 0px;
    top: 52px;
    width:60% ;
    height: 5px;
    background: #293B5C;

}
.prev {
    position: absolute;
    top:50%;
    left: 0;
    z-index: 1;
}
.next {
    position: absolute;
    top:50%;
    right: 0;
    z-index: 1;
}
svg {
    width: 22px;
    height: 24px;
   

}


.about-img {
   height: 100%;
}
@media ${device.tablet} {
    padding-top: 120px;
padding-bottom: 120px;

}

@media ${device.laptop} {
  .next,
  .prev {
    display: none;
  }
}
`
const Wrapper = styled.div `
position: relative;
padding-top: 70px ;
`






const Cooperation = () => {
    const data = useStaticQuery(graphql`
    {
      allFile(filter: {relativeDirectory: {eq: "cooperation"}}) {
        nodes {
          name
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, placeholder: TRACED_SVG, width: 322)
          }
         
        }
      }
    }
  `)
  
 
  const nodes = data.allFile.nodes
  
  const prevRef = useRef(null);
  const nextRef = useRef(null);
 
 


  return (
        <Globalwrapper id="custom" className="globalWrapper">
            <h1>Współpracujemy z :</h1>
          
            <Wrapper>
          <Swiper
            spaceBetween={16}
            slidesPerView={1}
            navigation={{
              prevEl: prevRef.current ? prevRef.current : undefined,
              nextEl: nextRef.current ? nextRef.current : undefined,
            }}
            onInit={(swiper) => {
              swiper.params.navigation.prevEl = prevRef.current;
              swiper.params.navigation.nextEl = nextRef.current;
              swiper.navigation.update();
            }}
            breakpoints={{
              320: {
                slidesPerView:1,
              },
              991: {
                slidesPerView: 3,
              },
            }}
          >
            {nodes.map((image, ) => {
        const { name } = image
        const pathToImage = getImage(image)
        return (
            <SwiperSlide>
            <GatsbyImage
              image={pathToImage}
              alt={name}
              className="About-img"
              as="div"
            />
           </SwiperSlide>
              )
        })}
            <div className="flex flex-row  ">
              <div ref={prevRef} className="cursor-pointer">
                <span className="prev"><AiFillLeftCircle/></span>
              </div>
              <div ref={nextRef} className="cursor-pointer">
                <span className="next"><AiFillRightCircle/></span>
              </div>
            </div>
          </Swiper>
          </Wrapper>
        </Globalwrapper>
      );
    }


export default Cooperation
