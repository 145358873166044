import React, { useState } from 'react'
import logo from '../../assets/images/logo/logo_2.png'
import { FiAlignJustify } from '@react-icons/all-files/fi/FiAlignJustify'
import styled from 'styled-components'
import { device } from '../Device/Device'
import { AnchorLink } from 'gatsby-plugin-anchor-links'

const NavigationWrapper = styled.nav`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  z-index: 1000;
  padding-top: 30px;

  @media (min-width: 992px) {
    flex-direction: row;
    padding-top: 10px;
    padding-left: 90px;
  }
`
const Navbar = styled.ul`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 0;
  margin: 0;
  list-style: none;
  color: #fff;
  width: 100%;
  font-family: 'Montserrat', sans-serif;

  .nav-btn {
    position: absolute;
    top: 22px;
    right: 20px;
    left: unset;

    color: #dfb34c;
    background-color: transparent;
    border: none;
  }
  .nav-btn svg {
    font-size: 50px;
  }
  .nav-links {
    height: 0;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    width: 100%;
    transition: 0.7s ease-in-out all;
  }
  .show-links {
    height: 50px;
    backdrop-filter: blur(4px);
  }

  .nav-link {
    transition: 0.3s ease-in-out all;
    font-weight: 700;
  }

  @media screen and (min-width: 992px) {
    flex-direction: row;
    align-items: center;

    .nav-btn {
      display: none;
    }
    .nav-links {
      height: auto !important;
      flex-direction: row;
      align-items: center;
      width: 100%;
    }
    .nav-link:hover {
      color: rgba(223, 179, 76, 1);
    }
  }
`
const Logo = styled.a`
  text-transform: uppercase;
  cursor: pointer;
  white-space: nowrap;

  img {
    width: 150px;
    height: 100%;
    filter: grayscale(100%);
  }

  @media ${device.laptop} {
    padding-right: 20%;
  }
`
const NavbarItems = styled.li`
  text-transform: uppercase;
  text-decoration: none;
  color: #fff;
  cursor: pointer;
  transition: 0.3s ease-in-out all;
  padding-bottom: 20px;
  padding-top: 20px;
  background-color: transparent;
  width: 100%;
  align-self: center;

  a {
    text-decoration: none;
    color: #fff;
    padding: 10px 5px;
    text-align: center;
    display: block;
  }
`

function Navigation() {
  const [show, setShow] = useState(false)
  const navbarItemsArray = [
    {
      name: 'Kim Jesteśmy',
      link: '/#about',
    },
    {
      name: 'Nasze Usługi',
      link: '/#services',
    },
    {
      name: 'Projekty',
      link: '/#project',
    },
    // {
    //     name: "Zespół",
    //     link: "/#team"
    // },
    {
      name: 'Klienci',
      link: '/#custom',
    },
    {
      name: 'Kontakt',
      link: '/#contact',
    },
  ]

  return (
    <NavigationWrapper className="globalWrapper">
      <Navbar>
        <Logo to="/">
          <img className="logo" src={logo} alt="intysun" />
        </Logo>
        <button className="nav-btn" onClick={() => setShow(!show)}>
          <FiAlignJustify />
        </button>
        {navbarItemsArray.map((item) => {
          return (
            <div className={show ? 'nav-links show-links' : 'nav-links'}>
              <NavbarItems>
                <AnchorLink
                  to={item.link}
                  className="nav-link"
                  activeClassName="active-link"
                  onClick={() => setShow(false)}
                >
                  {item.name}
                </AnchorLink>
              </NavbarItems>
            </div>
          )
        })}
      </Navbar>
    </NavigationWrapper>
  )
}

export default Navigation
