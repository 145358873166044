import React from 'react'
import styled from 'styled-components'
import { StaticImage } from "gatsby-plugin-image"
import { device } from '../Device/Device'



const Globalwrapper = styled.section `
position: relative;
top: -187px;
/* padding-top: calc(106px - 318px); */
z-index: -1;
`
const Wrappertop = styled.div `
position: relative;
height: 100%;
padding-bottom: 50px;
background: rgba(180, 176, 176, 0.3);
z-index: 1;
&:after {
    content: "";
    display: block;
    position: absolute;
    bottom: 0;
   left: 0;
    width: 0;
    height: 0;
    border-top: 142px solid #fff;
    border-right: 100vw solid #fff;
    z-index: -1;
  }

  .about-img {
   height: 100%;
   z-index: 1;
  }

 @media ${device.laptop} {
   &:after {
    border-top: 300px solid #fff;
   }
 }
`

const Title = styled.h1 `
padding: 195px 0px 0 0px;
position: relative;
font-weight: normal;
white-space: pre-wrap;
&::before{
  content: "";
  position: absolute;
  width: 39%;
    height: 5px;
    left: 0;
    top: calc(45px + 195px);
  background: #293B5C;
 

}
@media ${device.tablet} {
    width: max-content;
    &::before{
      left: 0;
    }
  }
`







  
 
  
 

const Projects = () =>{
  return (
<Globalwrapper  >
         
    <Wrappertop id="project" className="globalWrapper">
    <Title>Działamy na terenie całej Polski</Title>   
    <StaticImage
      src="../../assets/images/mapka_realizacje.png"
      alt="mapa polski"
      placeholder="blurred"
      layout="CONSTRAINED"
      width={816}
      className="about-img"
      as="div"
    />
    </Wrappertop>
   
    </Globalwrapper>
  )
}




export default Projects
