import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import styled from 'styled-components'
import { device } from '../Device/Device'

const Wrapper = styled.footer`
  background: rgba(41, 59, 92, 1);
  color: #fff;
  display: flex;
  .footer-upper {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 30px 0;
  }
  .descwrapper {
    display: flex;
    flex-direction: column;
  }
  .logo {
    width: 200px;
  }
  .topic-block {
    padding: 15px 0;
  }

  .information {
    padding: 15px 0;
  }
  .information p {
    padding-bottom: 15px;
  }
  @media ${device.laptop} {
    .footer-upper {
      flex-direction: row;
      justify-content: space-between;
    }
    .descwrapper {
      flex-basis: 50%;
      flex-direction: row;
      justify-content: space-between;
    }

    .logo {
      width: 400px;
      height: 100px;
      align-self: center;
    }
  }
`
const Footer = () => {
  return (
    <Wrapper className="globalWrapper">
      <div className="footer-upper">
        <StaticImage
          src="../../assets/images/logo_2.png"
          alt="logo"
          placeholder="blurred"
          layout="full_width"
          className="logo"
        />
        <div className="descwrapper">
          <div className="topic-block">
            <h2>Biuro zarządu</h2>
            <p>Al. Jana Pawła II 34</p>
            <p>00-141 Warszawa</p>
            +48 797 800 293
          </div>
          <div className="topic-block">
            <h2>Biuro projektowe</h2>
            <p>ul. Warszawska 25a</p>
            <p>25-001 Kielce</p>
            biuro@intisun.pl
          </div>
          <div className="information">
            <p>KRS: 0000876679</p>
            <p>NIP: 8133850658</p>
            <p>REGON: 387816902</p>
            www.intisun.pl
          </div>
        </div>
      </div>
    </Wrapper>
  )
}

export default Footer
