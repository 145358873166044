import React from 'react'
import { StaticImage } from "gatsby-plugin-image"
import styled from 'styled-components'
import {device} from '../Device/Device'
import ScrollAnimation from 'react-animate-on-scroll';



const Globalwrapper = styled.section`
background-color: #fff;
position: relative;
padding-bottom: 100px;
&::before {
    content: "";
    display: block;
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 0;
    border-right: 974px solid rgba(180, 176, 176, 0.3);
    border-left: 0 solid transparent;
    border-top: 200vh solid transparent;
    z-index: 2;
}

.about-img {
    height: 100%;
    z-index: 2;
    align-self: center;
}

img {
    display: block;
    margin: 0 auto;
    width: 100%;
}
h1 {
    position: relative;
    width: max-content;
    font-weight: normal;
}
h1::before {
    content: "";
    position: absolute;
    width: 80%;
    height: 5px;
    left: 0px;
    top: 44px;
    background: #293B5C;

}

`
const Wrapper = styled.div `
display: flex;
justify-content: space-between;
flex-direction: column;
@media ${ device.laptop } {
    flex-direction: row;
  }
`
const Wrapperdesc = styled.div`
width: 100%;
padding: 60px 20px 0 20px; 
transition: all 0.4s;



p {
    padding-bottom: 30px;
    text-align: justify;
    transition: all 0.4s;
}
@media ${device.tablet} {
    padding-left: 5%;
    
    p:last-child {
        position: relative;
        padding-bottom: 50px;
    }
}
`
const AboutUs = () => {
    return (
        <Globalwrapper>
            
            <Wrapper id="about">
                <Wrapperdesc >
                <h1>Kim jesteśmy</h1>
                <ScrollAnimation animateIn='fadeInDown'>
                <p>IntiSun Energy tworzy zespół specjalistów z branży energetycznej i inwestycyjnej. Połączyła nas wiara w to, że odpowiedzialność za otoczenie społeczne i biznesowe polega na inspirowaniu zmian w podejściu do energii, a dbanie o środowisko naturalne niesie ze sobą korzyści ekonomiczne dla domu, firmy oraz inwestora.</p>
                </ScrollAnimation>
                <ScrollAnimation animateIn='fadeInDown'>
<p>Naszym celem jest, aby nasi klienci osiągali wymierne korzyści ekonomiczne dzięki inwestycji w farmy fotowoltaiczne oraz produkcję energii elektrycznej z paneli fotowoltaicznych, jednocześnie dbając o środowisko naturalne. Dla gospodarstw domowych oraz przedsiębiorstw korzyść oznacza przede wszystkim niższe rachunki za prąd, a dla inwestorów – zyski ze sprzedaży energii elektrycznej.</p>
</ScrollAnimation>
</Wrapperdesc>
    <StaticImage
      src="../../assets/images/aboutus/o nas.png"
      alt="zespół"
      placeholder="blurred"
      layout="CONSTRAINED"
      width={816}
      className="about-img"
      as="div"
    />
    </Wrapper>
            
        </Globalwrapper>
    )
}

export default AboutUs
