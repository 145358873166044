import * as React from 'react'
import Layout from '../components/Layout/Layout'
import Background from '../components/Background/Background'
import '../assets/css/main.css'
import Services from '../components/Services/Services'
import Projects from '../components/Projects/Projects'
import Team from '../components/Team/Team'
import Contact from '../components/Contact/Contact'
import Sellingpoint from '../components/Sellingpoint/Sellingpoint'
import Cooperation from '../components/Cooperation/Cooperation'
import AboutUs from '../components/Aboutus/AboutUs'
import ScrollToTop from '../components/ScrollToTop/ScrollToTop'
import 'animate.css/animate.compat.css'

const IndexPage = () => {
  return (
    <Layout>
      <ScrollToTop />
      <Background />
      <AboutUs />
      <Services queryName="services" />
      <Projects />
      {/* <Team queryName="team"/> */}
      <Sellingpoint queryName="sellingpoint" />
      <Cooperation />
      <Contact />
    </Layout>
  )
}

export default IndexPage
