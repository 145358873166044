import React from 'react'
import {useStaticQuery, graphql} from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import styled from 'styled-components'
import Button from '../Buttons/Button'
import { device } from '../Device/Device'
import ScrollAnimation from 'react-animate-on-scroll';



const Globalwrapper = styled.section`
position: relative;
top: -50px;
left: 0;
z-index: 3;
width: 100%;
background-color: #293B5C;
margin-left: auto;
margin-right: auto;
color: #fff;
padding: 60px 0;
@media ${device.tablet} {
      width: 90%;
      top: -45px;
}

`
const Title = styled.h1`
display: block;
width: fit-content;
position: relative;
margin: 0 auto;
font-weight: normal;
    &:before {
        content: "";
        position: absolute;
        width: 80%;
        height: 5px;
        top: 50px;
        background: #DFB34C;
    }
`

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  gap: 1rem;
  
  
  

  .item {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 24px;
      flex-basis: 100%;
      padding-top: 30px;
      text-align: left;
      @media (min-width: 768px){
          flex-basis: 40%;
          padding-top: 60px;
        p{
              flex-grow: 1;
              text-align: justify;
              height: 100px;
          }
          h2{
              flex-grow: 1;
              text-align: center;
              height: 50px;
          }

      }
      @media (min-width: 992px) {
          flex-basis: 25%;
         
      }
    
  }
  .gallery-img {
      align-self: center;
      height: 81px;
    border-radius: 1rem;
  }
  
`

const Services = ({ queryName }) => {
  const data = useStaticQuery(graphql`
    {
      allFile(filter: {relativeDirectory: {eq: "services"}}) {
        nodes {
          name
          childImageSharp {
            gatsbyImageData(layout: FIXED, placeholder: TRACED_SVG, width: 79)
          }
          childServicesJson {
            data {
              desc
              name
              title
              
            }
          }
        }
      }
    }
  `)
  
 
  
  const nodes = data.allFile.nodes
  const images = nodes.filter(item => item.name !== queryName);
  const jsonTxt = nodes.filter(item => item.name == queryName)
  console.log(nodes)
  console.log(images)
  console.log(jsonTxt[0])


  return (
<Globalwrapper id="services">
          <Title>Nasze Usługi</Title>
    <Wrapper  className="globalWrapper" >
        
    {jsonTxt[0] ? jsonTxt[0].childServicesJson.data.map((item, index) => {
              const image = images.find(el => el.name == item.name);
              const { name, desc, title } = item
              const pathToImage = getImage(image)
              return (
                <article key={index} className="item">
                <GatsbyImage
                  image={pathToImage}
                  alt={name}
                  className="gallery-img"
                />
                  <ScrollAnimation animateIn='fadeInDown'>
               <h2>{title}</h2>
               </ScrollAnimation>
               <ScrollAnimation animateIn='fadeInDown'>
                <p>{desc}</p>
                </ScrollAnimation>
                <Button 
                link="/#contact" 
                name="Skontaktuj się z nami"/>
              </article>
              
              )
        }) : null}
        
      
    </Wrapper>
    
    </Globalwrapper>
    
  )
}




export default Services
