import React from 'react'
import {useStaticQuery, graphql} from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import styled from 'styled-components'
import ScrollAnimation from 'react-animate-on-scroll';




const Globalwrapper = styled.section`
position: relative;
top: -187px;
width: 100%;
background-color: #293B5C;
margin-left: auto;
margin-right: auto;
color: #fff;
padding: 60px 0;
@media (min-width: 992px) {
      width: 100%
}
`



const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  gap: 1rem;
  padding: 1rem 2rem;
  @media (min-width: 992px) {
      padding: 2% 5%;

  }

  .item {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 24px;
      flex-basis: 100%;
      padding-top: 30px;
      text-align: left;
      h2 {
        text-align: center;
      }
      p{
              flex-grow: 1;
              text-align: justify;
          }
      @media (min-width: 768px){
          flex-basis: 40%;
        p{
              flex-grow: 1;
              text-align: justify;
          }
          h2{
              flex-grow: 1;
              
          }

      }
      @media (min-width: 992px) {
          flex-basis: 25%;
         
      }
    
  }
  .gallery-img {
      align-self: center;
      height: 81px;
    border-radius: 1rem;
  }
  
`

const Sellingpoint = ({ queryName }) => {
    const data = useStaticQuery(graphql`
    {
      allFile(filter: {relativeDirectory: {eq: "sellingpoint"}}) {
        nodes {
          name
          childImageSharp {
            gatsbyImageData(layout: FIXED, placeholder: TRACED_SVG, width: 79)
          }
          childSellingpointJson {
            data {
              desc
              name
              title
              
            }
          }
        }
      }
    }
  `)
  
 
  
  const nodes = data.allFile.nodes
  const images = nodes.filter(item => item.name !== queryName);
  const jsonTxt = nodes.filter(item => item.name == queryName)
  console.log(nodes)
  console.log(images)
  console.log(jsonTxt[0])


  return (
<Globalwrapper>
          
    <Wrapper>
        
        {jsonTxt[0] ? jsonTxt[0].childSellingpointJson.data.map((item, index) => {
              const image = images.find(el => el.name == item.name);
              const { name, desc, title } = item
              const pathToImage = getImage(image)
              return (
                <article key={index} className="item">
                <GatsbyImage
                  image={pathToImage}
                  alt={name}
                  className="gallery-img"
                />
                  <ScrollAnimation animateIn='fadeInDown'>
               <h2>{title}</h2>
               </ScrollAnimation>
               <ScrollAnimation animateIn='fadeInDown'>
                <p>{desc}</p>
                </ScrollAnimation>
              </article>
              )
        }) : null}
      
    </Wrapper>
    </Globalwrapper>
  )
}




export default Sellingpoint
