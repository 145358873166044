import React from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import styled from 'styled-components'
import BackgroundSlider from 'gatsby-image-background-slider'
import Button from '../Buttons/Button'
import{ device } from "../Device/Device"


const WrapperSlider = styled.div`
position: relative;
height: 100vh;
background: linear-gradient(180deg, rgba(41, 59, 92, 0.7) 3.24%, rgba(41, 59, 92, 0) 100%);

&::before {
    content: "";
    display: block;
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 0;
    border-left: 0 solid #fff;
    border-right: calc(100vh - 974px) solid transparent;
    border-top: 100vh solid transparent;
    z-index: 1;

}
.gatsby-image-wrapper {
    height: 100vh !important;
}
.wrapper-slide {
    position: absolute;
    top: 40%;
    left: 15%;
    max-width: 80%;
    color: #fff;
    z-index:10;
    font-family: 'Montserrat', sans-serif;
   

}
h1 {
  font-size: 24px;
    font-weight: 700;
    margin-bottom: 1rem;
    
}
span {
  display: block;
  font-size: 18px;
  line-height: 1.4;
 margin-bottom: 2rem;
}
.btn {
    padding: 16px 32px;
    width: max-content;
}
@media ${device.laptop}{
    h1{
        font-size: 36px;
        
    }
    .wrapper-slide {
        top: 33%;
        width: 50%;
    }
}
@media ${device.laptopL} {
    h1 {
        font-size: 44px;
    }
    span{
      font-size: 24px;
    }
}
`

const Background = () => {
    return (
        <WrapperSlider >
        <BackgroundSlider 
      query={useStaticQuery(graphql`
        query {
          backgrounds: allFile (filter: {sourceInstanceName: {eq: "images"}}){
            nodes {
              relativePath
              childImageSharp {
                fluid (maxWidth: 4000, quality: 100){
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      `)}
      initDelay={2} // delay before the first transition (if left at 0, the first image will be skipped initially)
      transition={4} // transition duration between images
      duration={8} // how long an image is shown
      // specify images to include (and their order) according to `relativePath`

      // pass down standard element props
      images={["energy_storage.jpg", "panels_electric.jpg", "power_electricity.jpg", "home-energy_concept.jpg"]} > 
      {/* Captions in sync with background images*/}
      <div className="wrapper-slide">
      <div className="slide1">
          <h1>Energia do działania dla przedsiębiorstw</h1><span> niezależność energetyczna Twojej firmy.</span>
        <Button link="/#contact" name="Sprawdź korzyści"/>
      </div>
    
      </div>
      <div className="wrapper-slide">
      <div className="slide2">
          <h1>Energia do działania dla przedsiębiorczych</h1> <span> specjalizujemy się w projektowaniu farm oraz instalacji {'>'} 50kWp </span>
          <Button link="/#contact"  name="Sprawdź korzyści"/>
      </div>
      </div>
      <div className="wrapper-slide">
      <div className="slide3">
          <h1>Energia do działania dla przedsiębiorców</h1> <span> zarabiaj na energii, którą produkujesz </span>
          <Button link="/#contact" name="Napisz do nas"/>
      </div>
      </div>
      <div className="wrapper-slide">
      <div className="slide4">
          <h1>Energia do działania dla gospodarstw domowych</h1> <span> dowiedz się jak możesz obniżyć rachunki za prąd niemal do 0!</span>
          <Button link="/#contact" name="Napisz do nas"/>
      </div>
      </div>
     
      <>{/* Giraffes don't talk; [they aren't real](https://chivomengro.com/2017/10/23/the-truth-comes-out-giraffes-are-a-hoax/) */}</>
      <a href="https://en.wikipedia.org/wiki/Tasmanian_devil#Conservation_status">
        I could use a hand
      </a>
      <div>I need to find better hobbies</div>
     
    </BackgroundSlider>
      </WrapperSlider>
    )
}

export default Background
